// @import "./globals.scss";
.home__button-container {
    margin-bottom: 4em;
    // filter: drop-shadow(2px 2px 3px rgba(255, 255, 255, 0.4));
    &:hover {
        cursor: pointer;
    }
}
.svgColorMain {
    .cta {
        &::before {
            background: rgba($main, 1);
        }
    }
}
.svgColorSecondary {
    .cta {
        &::before {
            background: rgba($primary-6, 1);
        }
    }
}
.cta {
    position: relative;
    margin: auto;
    padding: 1em;
    transition: all 0.2s ease-in-out;

    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        border-radius: 50%;
        // background: rgba($main, 1);
        width: 3.6em;
        height: 3.6em;
        transition: all 0.3s ease-in-out;
    }
    span {
        position: relative;
        font-size: var(--fs-600);
        font-weight: bold;
        vertical-align: middle;
        margin-left: 0.3em;

        font-weight: 600;
        font-family: "Red Hat Display";
        @include tablet {
            font-size: var(--fs-400);
        }
    }
    svg {
        position: relative;
        top: 0;
        margin-left: 10px;
        fill: none;
        transform: translateX(-5px);
        transition: all 0.2s ease-out;
    }
    &:hover:before {
        width: 95%;
        border-radius: 1rem;
    }
    &:hover svg {
        transform: translateX(0);
    }
    &:active {
        transform: scale(0.96);
    }
}
