.home__content {
    display: flex;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url("../images/test.svg");
        background-repeat: no-repeat;
        background-size: 85%;
        background-position: 100% bottom;
        // filter: opacity(0.5);
    }
}
.home_filler {
    flex-basis: 100%;
}
.home__container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    justify-content: center;
    padding-top: 5em;
    padding-left: 10em;
    @include phone {
        padding-left: 2em;
    }
    @include tablet {
        padding-left: 6em;
    }
    @include orientation(landscape) {
        @include tablet {
            padding-top: 8em;
        }
    }
}
.home__welcome {
    // font-family: "Tangerine", serif;
    font-size: var(--fs-500);
    margin-bottom: 1em;
    &::first-letter {
        font-size: var(--fs-600);
        // font-weight: bold;
        color: $primary-2;
        // float: left;
        // line-height: 0.4;
    }
}
.home__name {
    font-family: "Asul", sans-serif;
    font-size: var(--fs-800);
    font-weight: 900;
    color: $primary-3;
    margin-bottom: 0.4em;
    -webkit-text-stroke-width: 1px;
    -webkit-text-stroke-color: $primary-1;
}
.home__description {
    font-size: var(--fs-300);
    font-style: italic;
    line-height: 1.5em;
    width: 45%;
    // max-width: 50ch;
    margin-bottom: 6em;
    @include phone {
        width: 100%;
    }
    @include orientation(landscape) {
        @include tablet {
            width: 60%;
            // margin-bottom: 1em;
        }
    }
}
.home__button-container {
    display: flex;
    width: 45%;
    justify-content: space-between;
    align-items: flex-start;
    @include phone {
        display: none;
    }
    @include orientation(landscape) {
        @include tablet {
            display: none;
        }
    }
}
