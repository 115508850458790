.about__content {
    display: flex;
    justify-content: center;
    height: 100vh;
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url("../images/test.svg");
        background-repeat: no-repeat;
        background-size: 85%;
        transform: scaleY(-1);
        background-position: 100% bottom;
        // filter: opacity(0.5);
    }
}
.about__container {
    flex-basis: 40%;
    padding: 2em;
    @include tablet {
        padding: 1em;
        // flex-basis: 100%;
    }
    @include orientation(landscape) {
        @include tablet {
            padding: 1em;
            margin-top: 9em;
        }
        @include phone-landscape {
            margin-top: 3em;
        }
    }
}
.about__left-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include phone {
        padding-left: 2em;
        flex-basis: 90%;
    }
    @include tablet {
        // padding: 1em;
        // flex-basis: 100%;
        padding-left: 6em;
        flex-basis: 90%;
    }
    @include orientation(landscape) {
        @include tablet {
            padding-left: 2em;
            justify-content: center;
            align-items: flex-start;
        }
    }
    box-sizing: border-box;
}
.about__right-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include phone {
        display: none;
    }
    @include orientation(landscape) {
        @include tablet {
            display: none;
        }
    }
}
.text {
    width: 100%;
    font-size: var(--fs-300);
    margin-bottom: 1em;
    line-height: 1.4em;
    @include phone {
        font-size: var(--fs-200);
    }
    @include orientation(landscape) {
        @include tablet {
            font-size: var(--fs-300);
        }
    }
    @include phone-landscape {
        font-size: var(--fs-100);
    }
}
@include orientation(landscape) {
    @include tablet {
        .text-1 {
            width: 60%;
        }
        .text-2 {
            width: 80%;
        }
        .text-3 {
            width: 90%;
        }
    }
}
