.work__container {
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 2em 8em;
    width: 100%;
    @include phone {
        padding: 2em 1em;
    }
    @include tablet {
        padding: 2em;
    }
    @include phone-landscape {
        padding: 2em 3em 0 3em;
    }
}
.container__top {
    height: 2em;
    padding-top: 2em;
    margin-bottom: 1.5em;
    font-size: var(--fs-500);
    @include phone {
        padding-top: 3em;
        margin-left: 1em;
        font-size: var(--fs-300);
    }
    @include tablet {
        margin-left: 1em;
        padding-top: 6em;
    }
    @include orientation(landscape) {
        @include phone-landscape {
            margin-left: 1em;
            padding-top: 2em;
            font-size: var(--fs-300);
        }
    }
}
.container__bottom {
    display: flex;
    flex-direction: row;
    height: calc(100% - 2em);
}
.work__client-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-basis: 40%;
    @include phone {
        display: none;
    }
    @include tablet {
        display: none;
    }
    @include phone-landscape {
        display: none;
    }
}
.work__client {
    display: flex;
    align-items: center;
    font-family: "Asul", sans-serif;
    font-size: var(--fs-600);
    font-weight: 500;
    border-bottom: 1px solid #fff;
    padding: 0.5em 0.2em 0.4em 0.2em;
    // margin-bottom: 0.5em;
    width: 100%;
    transition: all 1s ease;
    border-bottom: 1px solid $primary-1;
    @include tablet {
        font-size: var(--fs-400);
    }
    &:hover {
        cursor: pointer;
        transform: translateX(-20px);
        background-color: $main;
        transition: all 1s ease-in-out;
    }
}
.work__client-current {
    // background: red;
    // font-weight: bold;
    transform: translateX(-20px);
    background-color: $main;
}
.work__client-card-container {
    flex-basis: 60%;
    padding-left: 3em;
    opacity: 1;
    transition: all 0.5s ease;
    .arrow {
        display: none;
    }

    @include phone {
        flex-basis: 100%;
        padding-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .arrow {
            display: block;
            span {
                font-size: 32px;
            }
        }
    }
    @include tablet {
        flex-basis: 100%;
        padding-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .arrow {
            display: block;
            span {
                font-size: 48px;
            }
        }
    }
    @include phone-landscape {
        flex-basis: 100%;
        padding-left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        .arrow {
            display: block;
            span {
                font-size: 48px;
            }
        }
    }
}
.work__client-card-container-hide {
    opacity: 0;
    transition: all 0.5s ease;
}
