.client__container {
    height: 80vh;
    width: 50vw;
    margin-bottom: 1em;
    overflow: hidden;
    display: flex;
    position: relative;
    img {
        object-fit: contain;
        width: 100%;
    }
    position: relative;
    @include phone {
        width: 75vw;
        height: 90%;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    @include tablet {
        width: 80vw;
        justify-content: center;
        align-items: baseline;
    }
    @include orientation(landscape) {
        @include tablet {
            width: 75vw;
            height: 100%;
        }
    }
}

.client__info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    // z-index: 5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgba(#fff, 0.8);
    opacity: 0;
    transition: all 0.5s ease;
    width: 75%;
    height: 80%;
    backdrop-filter: blur(6px);
    font-size: var(--fs-300);
    border: 1px $primary-1 solid;
    border-radius: 1em;
    @include phone {
        top: 0;
        left: 0;
        width: 100%;
        border: none;
        border-radius: 0;
        transform: unset;
        font-size: var(--fs-200);
        height: 90%;
        border-top: unset;
        opacity: 1;
        position: relative;
    }
    @include tablet {
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50%;
        border: none;
        border-radius: 0;
        transform: unset;
        opacity: 1;
        font-size: var(--fs-300);
    }
    @include orientation(landscape) {
        @include tablet {
            // height: 80%;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: none;
            border-radius: 0;
            transform: unset;
            font-size: var(--fs-300);
            // border-top: unset;
        }
    }
}
.client__info-show {
    // transform: translateY(0%);
    opacity: 1;
    transition: all 0.5s ease;
}
.client__text {
    padding: 0 4em;
    margin-bottom: 4em;
    @include phone {
        padding: 0 1em;
        margin-bottom: 1em;
    }
    @include orientation(landscape) {
        @include phone {
            font-size: var(--fs-100);
            margin-bottom: 1em;
        }
        @include phone-landscape {
            font-size: var(--fs-100);
            margin-bottom: 1.2em;
        }
    }
}
.client__testimonial {
    padding: 0 4em;
    font-style: italic;
    margin-bottom: 4em;
    @include phone {
        font-size: var(--fs-100);
        padding: 0 1em;
        margin-bottom: 2em;
    }
    @include orientation(landscape) {
        @include phone {
            font-size: var(--fs-100);
            margin-bottom: 1em;
        }
        @include phone-landscape {
            font-size: var(--fs-100);
            margin-bottom: 1.2em;
        }
    }
}
.client__testimonial-text {
    display: inline;
    &::after,
    &::before {
        content: '"';
    }
}
.client__testimonial-name {
    display: inline;
    font-weight: bold;
}
.client__link {
    width: 100%;
    text-align: center;
    a {
        // width: 100%;
        background: rgba($main, 1);
        padding: 0.5em 2em;
        border-radius: 0.5em;
        color: $primary-1;
        text-decoration: none;
        font-size: var(--fs-400);
    }
    @include phone {
        position: absolute;
        bottom: 1.5em;
        a {
            padding: 0.5em 1em;
            font-size: var(--fs-200);
        }
    }
}
