@import "./type-scale";
$breakpoints: (
    phone: 600px,
    tablet: 1024px,
    desktop: 1919px,
    largedesktop: 2560px,
);

@mixin mq($key) {
    $size: map-get($breakpoints, $key);
    @media only screen and (min-width: $size) {
        @content;
    }
}

@mixin phone {
    @media (max-width: 600px) {
        @content;
    }
}
@mixin phone-landscape {
    @media (max-width: 1023px) {
        @content;
    }
}
@mixin phone-landscape {
    @media (min-width: 601px) and (max-width: 845px) {
        @content;
    }
}
@mixin tablet {
    @media (min-width: 601px) and (max-width: 1439px) {
        @content;
    }
}

@mixin desktop {
    @media (max-width: 1920px) and(min-width:1440px) {
        @content;
    }
}
@mixin large-desktop {
    @media (min-width: 1920px) {
        @content;
    }
}

@mixin orientation($direction) {
    $orientation-landscape: "(orientation:landscape)";
    $orientation-portrait: "(orientation:portrait)";

    @if $direction == landscape {
        @media #{$orientation-landscape} {
            @content;
        }
    }
    @if $direction == portrait {
        @media #{$orientation-portrait} {
            @content;
        }
    }
}

:root {
    @each $screen-size, $font-size in $type-scale {
        @if $screen-size == phone {
            @each $size, $value in $font-size {
                --fs-#{$size}: #{$value};
            }
        } @else if $screen-size == tablet {
            @include mq(tablet) {
                @each $size, $value in $font-size {
                    --fs-#{$size}: #{$value};
                }
            }
        } @else if $screen-size == desktop {
            @include mq(desktop) {
                @each $size, $value in $font-size {
                    --fs-#{$size}: #{$value};
                }
            }
        } @else if $screen-size == largedesktop {
            @include mq(largedesktop) {
                @each $size, $value in $font-size {
                    --fs-#{$size}: #{$value};
                }
            }
        }
    }
}
