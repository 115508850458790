// @import './globals.scss';
.social {
    position: fixed;
    width: 3rem;
    height: 10rem;
    background-color: $primary-1;
    left: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    z-index: 3;
    border-right: 1px solid #aaa;
    border-top: 1px solid #aaa;

    a {
        svg {
            height: 1.5rem;
            cursor: pointer;
        }
    }
    @include phone {
        top: 0;
        right: 0;
        left: unset;
        height: 2rem;
        width: 8rem;
        border: none;
        flex-direction: row;
        a {
            svg {
                height: 1.3rem;
            }
        }
    }
    @include tablet {
        // .social {
        width: 4rem;
        height: 12rem;

        left: 0;
        bottom: 0;
        top: auto;

        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        // }
    }
    @include orientation(landscape) {
        @include tablet {
            width: 3em;
        }
    }
}
// @media (min-width: 501px) {
//     .social {
//         width: 4rem;
//         height: 12rem;

//         left: 0;
//         bottom: 0;
//         top: auto;

//         flex-direction: column;
//         align-items: center;
//         justify-content: space-around;
//     }
// }
