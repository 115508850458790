@import "./scss/reset";
@import "./scss/colors";
@import "./scss/mixins";
@import "./scss/type-scale";
@font-face {
    font-family: "dubielplainregular";

    src: url("./fonts/Dubiel-webfont.woff") format("woff");
    font-weight: normal;
    font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Ruda:wght@400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Red+Hat+Display:ital,wght@0,400;0,500;0,700;0,900;1,400;1,500;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Noto+Serif+JP:wght@200;300;400;500;600;700;900&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons+Outlined");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Asul:wght@400;700&display=swap");
//pages and components
@import "./scss/header";
@import "./scss/svgButton";
@import "./scss/home";
@import "./scss/about";
@import "./scss/work";
@import "./scss/clientCard";
@import "./scss/contact";
@import "./scss/social";

body,
html {
    // background: $background;
    color: $primary-1;
    font-family: "Inter", sans-serif;
    // background-image: url("./images/back.jpg");
    // background-attachment: fixed;
    // background-size: cover;
    // background: $primary-6;
}
.app__section {
    min-height: calc(100vh - 5em);
    position: relative;
    @include orientation(landscape) {
        @include tablet {
            // height: 3em;
            min-height: calc(100vh - 3em);
        }
    }
}
