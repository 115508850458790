.contact__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    font-size: var(--fs-500);
    padding-right: 3em;
    @include phone {
        align-items: center;
        padding-inline: 2em;
        font-size: var(--fs-300);
    }
    @include tablet {
        align-items: center;
        padding-inline: 2em;
    }
    @include phone-landscape {
        align-items: center;
        padding-inline: 4em;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url("../images/test.svg");
        background-repeat: no-repeat;
        background-size: 85%;
        transform: scaleX(-1);
        background-position: 100% bottom;
        // filter: opacity(0.5);
    }
}
.contact__heading {
    margin-bottom: 2em;
}
.contact__info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    @include phone-landscape {
        margin-left: 3em;
    }
    div {
        margin-bottom: 1em;
        z-index: 2;
        label {
            width: 4em;
            display: inline-block;
        }
        a {
            text-decoration: none;
            color: $main-dark;
            &:hover {
                cursor: pointer;
            }
        }
    }
}
