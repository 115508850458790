nav {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    height: 5em;
    padding: 0.5em 2em;
    position: fixed;
    background: $background;
    z-index: 1;
    @include phone {
        background: transparent;
    }
    @include orientation(landscape) {
        @include tablet {
            height: 4em;
        }
    }
}
.header__logo {
    height: 6em;
    width: 6em;
    margin-top: 2em;
    flex-basis: 50%;
    @include phone {
        height: 3em;
        width: 3em;
        margin-top: 0;
    }
    @include orientation(landscape) {
        @include phone {
            margin-top: 0;
        }

        @include tablet {
            height: 3em;
            width: 3em;
        }
        @include phone-landscape {
            margin-top: 0;
        }
    }
}
.header__menu {
    flex-basis: 50%;
    display: flex;
    justify-content: space-between;

    span {
        color: $primary-4;
        font-size: var(--fs-300);
    }
    .header__active {
        color: $primary-1;
        font-weight: 800;
        border-bottom: 2px $primary-1 solid;
    }
    @include phone {
        position: fixed;
        bottom: 0;
        left: 0;
        display: flex;
        width: 100%;
        justify-content: space-around;
        align-items: center;
        height: 5em;
        padding: 0.5em 0em;
        background: rgba($background, 0.8);
    }
}
